import { useEffect, useState, useRef } from 'react';
import { CurrentUser, DropdownOption } from 'types';
import { DropDownSearch } from './DropDownSearch';
import { categoriesSearch } from 'api';
import { ResultItem } from 'components/SearchAutocompleteResult';

import cx from 'classnames';
import styles from './DropDownSearch.module.scss';
import { hi } from 'date-fns/locale';

export type HeaderSearchType = {
  headerSearch?: boolean;
  id?: string;
  placeholder?: string;
  searchOptions?: DropdownOption[];
  user?: CurrentUser;
  showQueryInUsers?: boolean;
};

const DEBOUNCE_TIMEOUT = 100;

const isShowAutocomplete = (type: number): boolean => {
  return type === 1;
};

const search = async (q: string, callback: (result: Array<ResultItem>) => void, showQueryInUsers = false) => {
  if (q.length < 2) {
    callback([]);
    return;
  }

  const response = await categoriesSearch(q);
  const hits = response.hits;
  const result: Array<ResultItem> = prepareSearchResult(q, hits, showQueryInUsers);

  callback(result);
};

const prepareSearchResult = (q: string, hits: Array<any>, showQueryInUsers = false) => {
  const result: Array<ResultItem> = [];

  result.push({
    id: 'all_markets',
    title: q,
    description: 'in All Markets',
    overlapped: true,
    lineDown: hits.length > 0 || showQueryInUsers,
  });

  hits.forEach((item: any) =>
    result.push({
      id: item.type + item.id,
      title: item.title,
      meta: {
        slug: item.slug,
      },
    }),
  );

  if (showQueryInUsers) {
    result.push({
      id: 'all_users',
      title: q,
      description: 'in Users',
      overlapped: true,
      lineUp: hits.length > 0,
    });
  }

  return result;
};

export const HeaderSearch = ({ user, headerSearch, placeholder, searchOptions, id, showQueryInUsers = false }: HeaderSearchType) => {
  const [type, setType] = useState<number>(1);
  const [showResult, setShowResult] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [result, setResult] = useState<Array<ResultItem>>([]);
  // count refs for show preloader
  const autocompleteLoadersCount = useRef(0);
  const inputsCount = useRef(0);

  useEffect(() => {
    setShowResult(isShowAutocomplete(type));
    resetLoading();
  }, [type]);

  const handleChangeDropDownValue = (value: number) => {
    setType(value);
  };

  // LOADING TRACKER
  const addAutocompleteLoader = () => {
    autocompleteLoadersCount.current = autocompleteLoadersCount.current + 1;
    handleLoading();
  }

  const removeAutocompleteLoader = () => {
    if (autocompleteLoadersCount.current > 0) {
      autocompleteLoadersCount.current = autocompleteLoadersCount.current - 1;
      handleLoading();
    }
  };

  const handleLoading = () => {
    setIsLoading(autocompleteLoadersCount.current > 0 || inputsCount.current > 0);
  };

  const resetLoading = () => {
    autocompleteLoadersCount.current = 0;
    inputsCount.current = 0;
    handleLoading();
  };
  // END LOADING TRACKER

  const handleInputQuery = (q) => {
    if (showResult) {
      if (q?.length > 1) {
        inputsCount.current = inputsCount.current + 1;
        handleLoading();
        setResult(prepareSearchResult(q, [], showQueryInUsers)); // set results not dependent of server request
        setTimeout(() => {
          if (inputsCount.current > 0) {
            inputsCount.current = inputsCount.current - 1;
            handleLoading();
          }
        }, DEBOUNCE_TIMEOUT);
      } else {
        setResult([]); // set empty results instantly (without debounce timeout waiting)
      }
    }
  };

  const handleChangeQuery = (q: string) => {
    if (showResult) {
      if (q) {
        addAutocompleteLoader(); // increment requests count for track loading process
        search(q, (result: Array<ResultItem>) => {
          setResult(result);
        }, showQueryInUsers).then(() => {
          removeAutocompleteLoader(); // decrement requests count or track loading process
        });
      } else {
        resetLoading();
        setResult([]);
      }
    } else {
      setResult([]);
    }
  };

  const handleClickResultItem = (item: ResultItem) => {
    if (item.id === 'all_users') {
      return window.location.assign(`/users?q=${item.title}`);
    }

    window.location.assign(`/gear?q=${item.title}`);
  };

  const handleEnterSearch = (q: string) => {
    if (type === 2) {
      return window.location.assign('/users?q=' + encodeURIComponent(q));
    }
    window.location.assign(`/gear?q=${encodeURIComponent(q)}`);
  };

  return (
    <div className={cx({ [styles.headerSearch]: headerSearch, [styles.commonSearch]: !headerSearch })}>
      <DropDownSearch
        id={id || 'header-search'}
        headerSearch={headerSearch}
        onChangeDropDownValue={handleChangeDropDownValue}
        onChangeQuery={handleChangeQuery}
        onInputQuery={handleInputQuery}
        onClickResultItem={handleClickResultItem}
        onEnterSearch={handleEnterSearch}
        placeholder={placeholder}
        searchOptions={searchOptions}
        showResult={showResult}
        result={result}
        user={user}
        value={type}
        debounceTimeout={DEBOUNCE_TIMEOUT}
        resultLoading={isLoading}
      />
    </div>
  );
};
